import React from 'react'
import styled from '@emotion/styled'
import arrow from '../assets/images/arrow6.png'

const Download = ({ section }) => {
  if (!section || !section.file) {
    return null
  }

  const fileURL = section.file.url.startsWith('//')
    ? `https:${section.file.url}`
    : section.file.url

  const DownloadContainer = styled.div`
    display: flex;
    justify-content: center;

    .download {
      padding: 20px 0 80px 0;
      display: flex;
      align-items: flex-start;
    }

    .arrowContainer {
      display: block;
      width: 25px;
      transform: rotate(90deg);
      margin-left: 10px;
    }

    a {
      color: white;
      text-decoration: none;
      font-size: 1.6rem;
    }
  `

  return (
    <DownloadContainer>
      {section ? (
        <a
          href={fileURL}
          title={section.file.fileName}
          download
          target="_blank"
          className="download"
          onClick={e => {
            console.log('Download button clicked')
            // To stop the page reloading
            // e.preventDefault()
            // Lets track that custom click
            // trackCustomEvent({
            //   // string - required - The object that was interacted with (e.g.video)
            //   category: 'Special Button',
            //   // string - required - Type of interaction (e.g. 'play')
            //   action: 'Click',
            //   // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            //   label: 'Gatsby Plugin Example Campaign',
            //   // number - optional - Numeric value associated with the event. (e.g. A product ID)
            //   value: 43,
            // })
            //... Other logic here
            const result = window.dataLayer.push({ event: 'pdfDownload' })
            console.log(
              "window.dataLayer.push({ event: 'pdfDownload' })",
              result
            )
          }}
        >
          <p>Download PDF</p>
          <div className="arrowContainer">
            <img src={arrow} alt="arrow" />
          </div>
        </a>
      ) : null}
    </DownloadContainer>
  )
}

export default Download
