import React from 'react'
import styled from '@emotion/styled'
import TextEffect from './riseEffect/TextEffect'

const Body = styled.div`
  /* border-top: 1px solid white; */
  padding: 80px 0;
  display: flex;
  border-top: 1px solid white;
  margin: auto 40px;
  /* padding-top: 40px; */

  .resultContainer {
    width: 180px;
    /* margin: 0 40px; */
  }

  .bodyContainer {
    width: calc(100% - 100px);
    font-weight: 300;
    display: flex;
    flex-direction: column;
  }

  .linksContainer {
    width: calc(100% - 100px);
    font-weight: 300;
    display: flex;
    flex-direction: column;
    height: 100px;
  }

  a {
    color: white;
    text-decoration: none;
    line-height: 1.6;
  }

  h3 {
    font-family: 'NeueHaasGrotesk', serif !important;
    line-height: 1.6;
    font-weight: 700;
    font-size: 0.9rem;
  }

  p {
    line-height: 1.6;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    .resultContainer,
    .bodyContainer {
      width: 100%;
    }

    .resultContainer {
      padding-bottom: 15px;
    }
  }
`

const PageBody = props => {
  const links = props.links
  console.log('paragraph', props)

  return (
    <Body>
      <div className="resultContainer">
        <h3>{props.body.bodyTitle ? props.body.bodyTitle : 'Results'}</h3>
      </div>
      <div className="bodyContainer">
        {props.body && (
          <>
            <TextEffect
              lineHeight={1.6}
              text={props.body.bodyParagraph.childMarkdownRemark.html}
            ></TextEffect>
          </>
        )}
        {/* {links && (
          <div className="linksContainer">
            {links.map((link, index) => (
              // eslint-disable-next-line react/jsx-key
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TextEffect lineHeight={1.6} text={link.text} delay={3} />
              </a>
            ))}
          </div>
        )} */}
      </div>
    </Body>
  )
}

export default PageBody
