import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

const Results = ({
  metric1Result,
  metric1Title,
  metric2Result,
  metric2Title,
  metric3Result,
  metric3Title,
}) => {
  const ResultsContainer = styled.section`
    .results {
      display: flex;
      justify-content: space-between;
      margin: auto 40px;
    }

    .metric1,
    .metric2,
    .metric3 {
      width: 27%;
    }

    h2 {
      border-bottom: 1px solid white;
      padding-bottom: 30px;
      font-family: 'NeueHaasGrotesk', serif !important;
      line-height: 1.6;
      font-weight: 700;
      font-size: 0.9rem;
    }

    h3 {
      font-size: 8vw;
      padding: 80px 0;
      font-family: 'NeueHaasGrotesk', serif !important;
    }

    @media (max-width: 550px) {
      padding-bottom: 20px;

      .results {
        flex-direction: column;
        align-items: center;
      }

      .metric1,
      .metric2,
      .metric3 {
        width: 280px;
      }

      h3 {
        font-size: 5rem;
        padding: 40px 0 60px 0;
      }
    }
  `

  return (
    <ResultsContainer>
      <div className="results">
        {metric1Result && metric1Title ? (
          <div className="metric1">
            <h2>{metric1Title}</h2>
            <h3>{metric1Result}</h3>
          </div>
        ) : null}
        {metric2Result && metric2Title ? (
          <div className="metric2">
            <h2>{metric2Title}</h2>
            <h3>{metric2Result}</h3>
          </div>
        ) : null}
        {metric3Result && metric3Title ? (
          <div className="metric3">
            <h2>{metric3Title}</h2>
            <h3>{metric3Result}</h3>
          </div>
        ) : null}
      </div>
    </ResultsContainer>
  )
}

export default Results
