import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import TextEffect from './riseEffect/TextEffect'

const Quote = ({ quote, quotePerson, quoteTitle }) => {
  const QuoteContainer = styled.section`
    display: flex;
    justify-content: center;

    .innerQuoteContainer {
      padding: 140px 0 180px 0;
      width: 60%;

      .quote {
        font-style: italic;
        font-size: 1.6rem;
        margin-bottom: 140px;
        position: relative;

        &::before {
          content: open-quote;
          position: absolute;
          top: -100px;
          left: -110px;
          color: white;

          font-size: 7rem;
        }

        &::after {
          content: close-quote;
          position: absolute;
          bottom: -150px;
          right: -40px;
          color: white;

          font-size: 7rem;
        }
      }

      .quoteFromContainer {
        display: flex;
        justify-content: flex-end;
        padding-right: 6%;
      }

      .quoteFrom {
        font-size: 1.2rem;
        text-align: right;
        position: relative;
        display: inline-block;

        span {
          font-weight: 700;
        }

        &::before {
          content: '';
          position: absolute;
          left: -62px;
          top: calc(50% - 3px);
          width: 40px;
          border-bottom: 1px solid white;
        }
      }
    }

    @media (max-width: 550px) {
      .innerQuoteContainer {
        padding: 120px 0 80px 0;

        width: 82%;

        .quote {
          font-size: 1.4rem;

          &::before {
            left: -45px;
          }

          &::after {
            right: -10px;
          }
        }
      }

      .quoteFromContainer {
        padding-right: 0 !important;

        .quoteFrom {
          font-size: 1rem;
        }
      }
    }
  `

  return (
    <QuoteContainer>
      {quote && quotePerson && quoteTitle ? (
        <div className="innerQuoteContainer">
          <div className="quote">
            <TextEffect lineHeight={1.2} text={quote} />
          </div>

          <div className="quoteFromContainer">
            <p className="quoteFrom">
              {quotePerson}, <span>{quoteTitle}</span>
            </p>
          </div>
        </div>
      ) : null}
    </QuoteContainer>
  )
}

export default Quote
