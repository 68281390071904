import React from 'react'
import styled from '@emotion/styled'

export class ScrollDrag extends React.Component {
  //  [scrollXAmount, setScrollXAmount] = useState(0)

  constructor(props) {
    super(props)
    this.ref = React.createRef()
    if (this.state) {
      // console.log(this.state.scrollX, 'scrollX line 7')
    }

    this.state = {
      isScrolling: false,
      clientX: 0,
      scrollX: this.props.scrollXAmount ? this.props.scrollXAmount : 0,
      maxScroll: null,
      imageWidth: 0,
      bodyWidth: 0,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.containerRef.current) {
      if (this.props.containerRef.current.scrollLeft == 0) {
        this.props.containerRef.current.scrollLeft = this.state.scrollX
      }
    }
  }

  componentDidMount() {
    if (this.props.containerRef.current) {
      this.props.containerRef.current.scrollLeft = this.state.scrollX
    }

    const width841 = window.matchMedia('(min-width: 841px)')
    const width550 = window.matchMedia(
      '(min-width: 401px) and (max-width: 550px)'
    )
    const width840 = window.matchMedia(
      '(min-width: 551px) and (max-width: 840px)'
    )
    const width400 = window.matchMedia('(max-width: 400px)')

    width841.addListener(this.handleMedia)
    width550.addListener(this.handleMedia)
    width840.addListener(this.handleMedia)
    width400.addListener(this.handleMedia)

    window.addEventListener('resize', this.displayWindowSize)

    const setUp = async () => {
      await this.handleMedia(width841)
      await this.handleMedia(width840)
      await this.handleMedia(width550)
      await this.handleMedia(width400)

      this.displayWindowSize()
    }

    setUp()
  }

  componentWillUnmount() {
    const width841 = window.matchMedia('(min-width: 841px)')
    const width550 = window.matchMedia(
      '(min-width: 401px) and (max-width: 550px)'
    )
    const width840 = window.matchMedia(
      '(min-width: 551px) and (max-width: 840px)'
    )
    const width400 = window.matchMedia('(max-width: 400px)')

    width841.removeListener(this.handleMedia)
    width840.removeListener(this.handleMedia)
    width550.removeListener(this.handleMedia)
    width400.removeListener(this.handleMedia)

    window.removeEventListener('resize', this.displayWindowSize)
  }

  handleMedia = mediaQuery => {
    if (
      mediaQuery.media === '(min-width: 551px) and (max-width: 840px)' &&
      mediaQuery.matches
    ) {
      this.setState({
        imageWidth: 500,
        bodyWidth: 0.9,
      })
    } else if (
      mediaQuery.media === '(min-width: 401px) and (max-width: 550px)' &&
      mediaQuery.matches
    ) {
      this.setState({
        imageWidth: 350,
        bodyWidth: 0.9,
      })
    } else if (
      mediaQuery.media === '(min-width: 841px)' &&
      mediaQuery.matches
    ) {
      this.setState({
        imageWidth: 700,
        bodyWidth: 0.94,
      })
    } else if (
      mediaQuery.media === '(max-width: 400px)' &&
      mediaQuery.matches
    ) {
      this.setState({
        imageWidth: 290,
        bodyWidth: 0.9,
      })
    }
  }

  displayWindowSize = () => {
    const scrollWidth = this.props.scrollWidth * this.state.imageWidth

    const setMaxScroll = async () => {
      const galleryWidth = await document.getElementById('imageGallery')
        .offsetWidth

      this.setState({
        maxScroll: (scrollWidth - galleryWidth) * -1,
      })
    }

    setMaxScroll()
  }

  onMouseDown = e => {
    this.setState({
      ...this.state,
      isScrolling: true,
      clientX: e.clientX,
    })
  }

  onMouseUp = () => {
    this.setState({ ...this.state, isScrolling: false })

    if (this.props.onScrollXChange) {
      this.props.onScrollXChange(this.state.scrollX)
    }
  }

  onMouseMove = e => {
    const { clientX, scrollX } = this.state

    if (this.state.isScrolling) {
      this.props.containerRef.current.scrollLeft =
        (scrollX + e.clientX - clientX) * -1

      this.setState({
        scrollX: scrollX <= 0 ? scrollX + e.clientX - clientX : 0,
      })

      if (scrollX <= 0 && scrollX >= this.state.maxScroll) {
        this.setState({
          scrollX: scrollX + e.clientX - clientX,
        })
      } else if (scrollX < 0 && scrollX <= this.state.maxScroll) {
        this.setState({
          scrollX: this.state.maxScroll,
        })
      } else if (scrollX > 0 && scrollX >= this.state.maxScroll) {
        this.setState({
          scrollX: 0,
        })
      }

      this.setState({
        clientX: e.clientX,
      })
    }
  }

  render() {
    const ImageGalleryContainer = styled.ul`
      display: flex;

      &:hover {
        cursor: pointer;
      }

      li {
        min-width: 700px;
        padding: 0 3vw 0 3vw;
      }

      .gatsby-image-wrapper {
        height: 0;
        padding-bottom: 63%;
      }

      img {
        -webkit-user-drag: none;
      }

      @media (max-width: 840px) {
        li {
          min-width: 500px;
        }

        li:first-child {
          padding: 0 2.5vw 0 5vw;
        }

        li:last-child {
          padding: 0 5vw 0 2.5vw;
        }
      }

      @media (max-width: 550px) {
        .gatsby-image-wrapper {
          padding-bottom: 75%;
        }

        li {
          min-width: 350px;
        }
      }

      @media (max-width: 400px) {
        li {
          min-width: 290px;
        }
      }
    `

    return (
      <ImageGalleryContainer
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
        id={`imageGallery`}
      >
        {React.Children.map(this.props.children, child =>
          React.Children.only(child)
        )}
      </ImageGalleryContainer>
    )
  }
}

export default ScrollDrag
