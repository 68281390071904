import React from 'react'
import Img from 'gatsby-image'
import ScrollDrag from './ScrollDrag'
import styled from '@emotion/styled'
import arrow from '../assets/images/arrow6.png'
import gsap from 'gsap'

export class ImageGallery extends React.PureComponent {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  componentDidMount() {
    const arrow = document.getElementById(`galleryArrow`)

    let timeline = gsap.timeline()

    timeline.to(arrow, 1, {
      duration: 1,
      x: `-20px`,
      repeat: -1,
      yoyo: true,
    })
  }

  containerRef = React.createRef()

  render() {
    const images = this.props.images

    if (!images || !Array.isArray(images)) {
      return <p>No images available</p> // Pode retornar algo como um carregador ou mensagem
    }

    const OverallContainer = styled.section`
      padding-bottom: 80px;

      .imageGallery {
        height: 400px;
        overflow: hidden;
        display: block;
        width: calc(100% * 100 / 94);

        transform: translateX(-3vw);
      }

      .swipe {
        display: none;
      }

      .instructions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        margin-bottom: 30px;

        p {
          font-size: 1.2rem;
          font-weight: 100;
          padding-right: 40px;
        }

        .arrowContainer {
          width: 40px;
        }
      }

      @media (max-width: 840px) {
        .imageGallery {
          width: calc(100% * 100 / 90);
          transform: translateX(-5vw);
          height: 255px;
        }

        .instructions {
          p {
            font-size: 1.1rem;
            padding-right: 30px;
          }

          .arrowContainer {
            width: 30px;
          }
        }
      }

      @media (max-width: 550px) {
        padding-bottom: 40px;

        .imageGallery {
          height: 225px;
        }
      }

      @media (max-width: 400px) {
        .imageGallery {
          height: 195px;
        }
      }

      @media (pointer: coarse) {
        .swipe {
          display: block;
        }

        .drag {
          display: none;
        }
      }
    `

    const ImageUpper = styled.div`
      overflow: scroll;
      overflow-y: hidden;
      height: 423.25px;

      @media (max-width: 840px) {
        height: 278.25px;
      }

      @media (max-width: 550px) {
        height: 248.25px;
      }

      @media (max-width: 400px) {
        height: 218.25px;
      }
    `

    return (
      <OverallContainer>
        <div className="instructions">
          <p className="drag">Click and Drag</p>
          <p className="swipe">Swipe</p>
          <div className="arrowContainer">
            <img src={arrow} alt="arrow" id={`galleryArrow`} />
          </div>
        </div>

        <div className="imageGallery">
          <ImageUpper ref={this.containerRef}>
            <ScrollDrag
              containerRef={this.containerRef}
              scrollWidth={images.length}
            >
              {images.map(image => {
                return (
                  <li key={image.id}>
                    <Img fluid={image.fluid} />
                  </li>
                )
              })}
            </ScrollDrag>
          </ImageUpper>
        </div>
      </OverallContainer>
    )
  }
}

export default ImageGallery
